import PropTypes from "prop-types";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Image,
  Navbar,
  Overlay,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";

import { Link, useLocation, useNavigate } from "react-router-dom";
import account from "../../assets/images/brands/account.svg";
import embed from "../../assets/images/brands/embed-green.svg";
import embedDisabledIcon from "../../assets/images/brands/embed-disabled-icon.svg";
import logoutImage from "../../assets/images/brands/header-account-icon.svg";
import { useAuth } from "../../utils/AuthContext";
import { getUser } from "../../utils/localStorage";
import home from "../../assets/modulis/ModulisLogo.svg";
import homeIcon from "../../assets/images/brands/header-home.svg";
import "../styles/header.css";
import { formatPathname, getLastPathnameValue } from "../../utils/helper";
const ImageComponent = lazy(() =>
  import("../../components/common/ImageComponent")
);

function Header({
  leftLabel,
  showEmbed,
  enableEmbed,
  handleEmbedClick,
  disabled = false,
  disabledText = "HOLA",
  logo = false,
}) {
  const location = useLocation();
  const [showPopover, setShowPopover] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const popoverRef = useRef(null);
  const targetRef = useRef(null);
  const user = getUser();

  const handleSignOut = () => {
    logout();
    setShowPopover(false);
  };

  useEffect(() => {
    const handleClickOutside = () => {
      setShowPopover(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const popover = (
    <Popover id="popover-basic" className="popup-container" ref={popoverRef}>
      <Popover.Header as="h3" className="popup-header">
        <div className="d-flex align-items-center gap-3">
          <div className="popup-header-container">
            <Image src={account} alt="account" width="30px" height="30px" />
          </div>
          <p className="m-0">
            {user?.user?.first_name} {user?.user?.last_name}
          </p>
        </div>
      </Popover.Header>
      <Popover.Body className="d-flex justify-content-between align-items-center p-2 popup-body">
        <Button
          className="m-0 popup-body-heading btn-link"
          onClick={() => {
            navigate("/my-account/profile");
            setShowPopover(false);
          }}
        >
          My Account
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={handleSignOut}
          className="popup-body-signup-btn"
        >
          Sign out
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <Navbar
        bg="#0E3279"
        expand="lg"
        className="border-bottom subscription-header-container w-100 px-3"
      >
        <Container fluid>
          <div className="d-flex direction-row align-items-center">
            {logo && (
              <Button className="" onClick={() => navigate("/")} variant="link">
                <ImageComponent
                  src={home}
                  className="img-fluid"
                  alt="authentication"
                  width={180}
                />
              </Button>
            )}
            <div className={"text-white fs-4 w-2"}>{leftLabel}</div>
          </div>
          <div>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll" className="justify-content-end">
              <div className="d-flex ">
                <Button
                  ref={targetRef}
                  className="header-logout-icons btn-logout"
                  onClick={() => setShowPopover(!showPopover)}
                >
                  <Suspense fallback={<div className="react-load"></div>}>
                    <ImageComponent
                      src={logoutImage}
                      className="img-fluid"
                      alt="authentication"
                    />
                  </Suspense>
                </Button>
                <Button
                  className="header-logout-icons btn-logout"
                  onClick={() => navigate("/")}
                >
                  <Suspense fallback={<div className="react-load"></div>}>
                    <div
                      style={{
                        border: "1px solid #F0B100",
                        borderRadius: "50%",
                        width: "28px",
                        height: "28px",
                      }}
                    >
                      <ImageComponent
                        src={homeIcon}
                        className="img-fluid rounded "
                        alt="authentication"
                      />
                    </div>
                  </Suspense>
                </Button>
              </div>
            </Navbar.Collapse>
          </div>
        </Container>
        <Overlay
          show={showPopover}
          target={targetRef.current}
          placement="bottom"
          containerPadding={20}
          rootClose={true}
          rootCloseEvent="mousedown"
        >
          {popover}
        </Overlay>
      </Navbar>
      <div className="header-breadcrumb d-flex justify-content-end">
        {/* <p
          className={
            logo
              ? "header-left-label header-breadcrumb-text text-black mx-4 fs-6"
              : "header-breadcrumb-text text-black mx-4 fs-6"
          }
        >
          <Link to="/">Home</Link> / {formatPathname(location)}
        </p> */}
        {(showEmbed || enableEmbed) && (
          <>
            <OverlayTrigger
              placement={disabled ? "left" : "bottom"} // or 'bottom', 'left', 'right'
              overlay={
                <Tooltip id="embed-tooltip">
                  {disabled ? disabledText : "Embed"}{" "}
                </Tooltip>
              }
            >
              {disabled ? (
                <Button
                  style={{ pointerEvents: "auto" }}
                  variant="link"
                  className="d-flex align-items-center embed-button"
                  onClick={handleEmbedClick}
                  disabled={disabled}
                >
                  <Image src={embedDisabledIcon} width={20} height={20} />
                </Button>
              ) : (
                <Button
                  style={{ pointerEvents: "auto" }}
                  variant="link"
                  className="d-flex align-items-center embed-button"
                  onClick={handleEmbedClick}
                  disabled={disabled}
                >
                  <Image src={embed} width={20} height={20} />
                </Button>
              )}
            </OverlayTrigger>
          </>
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  leftLabel: PropTypes.string,
};

Header.defaultProps = {
  leftLabel: "",
};

export default Header;
